

































import { Component, Vue } from "vue-property-decorator";
import SideMenu from "@/components/SideMenu.vue";
import { getUserInfo  } from "@/api";
@Component({
  components: {
    SideMenu,
  },
})
export default class Member extends Vue {
  get pathName(): string {
    return this.$route.meta.name
  }
  userInfo = {
    sex: 2,
    birthday: "1970-01-01",
    companyType: 1,
    nickName: "",
    loginDate: "1970-01-01 00:00:00"
  };

  getUserInfo(): void{
    getUserInfo({}).then(res => {
      if(res.status == 200){
          if(res.data.code == 200){
            this.userInfo = res.data.user
            window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          }else{
            this.$message.error(res.data.msg );
          }
        }else{
          this.$message.error(res.data.statusText);
        } 
      }).catch(() => {
        console.log(' error ')
      })
  };
  mounted(){
    this.getUserInfo();
  }
}
